<template>
  <div class="content">
    <div class="accountCon">
      <div class="btnBox">
        <div></div>
        <el-button type="primary" class="mb10" size="mini">
          <router-link tag="span" :to="{name: '添加基础设置'}">添加设置</router-link>
        </el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="名称" prop="name" align="center" />
            <ElTableColumn label="类型" prop="category_name" align="center" />

            <ElTableColumn label="添加时间" prop="create_time" align="center" />
            <ElTableColumn label="更新时间" prop="update_time" align="center" />
            <ElTableColumn label="操作" align="center" width="160">
              <template slot-scope="{ row }">
                <router-link
                  class="theme"
                  tag="span"
                  :to="{
                  name: '编辑基础设置', params: {id: row.id}
                }"
                >编辑</router-link>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { deleteSetting, settingList, statusSetting } from "@/api/client";

class Search {
  staffName = "";
  staffTel = "";
  statistics = 0;
  isInfo = "";
}
class Options {
  constructor() {
    this.statistics = [
      { id: 0, name: "全部" },
      { id: 1, name: "选项1" },
      { id: 2, name: "选项2" }
    ];
    this.isInfo = [
      { id: 0, name: "全部" },
      { id: 1, name: "是" },
      { id: 2, name: "否" }
    ]; // 代理人
  }
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "StaffList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: "",
      loading: false
    };
  },

  created() {
    this.getList();
  },
  filters: {
    statusFilter(val) {
      return [null, "启用", "禁用"][+val];
    }
  },
  methods: {
    // 获取列表
    getList() {
      this.loading = true;
      settingList(this.page).then(res => {
        const { list, dataCount } = res.data;
        this.list = list;
        this.page.total = dataCount;
        this.loading = false;
      });
    },
    // 点击详情
    tapDetail(id) {
      this.$confirm("是否永久删除该条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "waring"
      })
        .then(async () => {
          await deleteSetting({ id: id });
          this.getList();
        })
        .catch(() => {});
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    },
    handleChangeSetStatus(row) {
      statusSetting({
        id: row.id,
        status: row.status
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
