import { render, staticRenderFns } from "./basicSetting.vue?vue&type=template&id=18489253&scoped=true&"
import script from "./basicSetting.vue?vue&type=script&lang=js&"
export * from "./basicSetting.vue?vue&type=script&lang=js&"
import style0 from "./basicSetting.vue?vue&type=style&index=0&id=18489253&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18489253",
  null
  
)

export default component.exports